import Application from '../Application';
import BakedModel from '../Utils/BakedModel';
export default class Decor {
    constructor() {
        this.application = Application.getInstance();
        this.scene = this.application.scene;
        this.resources = this.application.resources;
        this.initModel();
    }
    initModel() {
        var _a, _b;
        const model = (_a = this.resources.items.gltfModel) === null || _a === void 0 ? void 0 : _a.decorModel;
        const texture = (_b = this.resources.items.texture) === null || _b === void 0 ? void 0 : _b.decorTexture;
        if (!model || !texture) {
            console.error('Decor.ts: Missing model or texture resources');
            return;
        }
        this.bakedModel = new BakedModel(model, texture, Decor.MODEL_SCALE, true);
        this.scene.add(this.bakedModel.getModel());
    }
    destroy() {
        if (this.bakedModel) {
            this.scene.remove(this.bakedModel.getModel());
        }
    }
}
Decor.MODEL_SCALE = 900;
