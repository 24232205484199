export default class Mobile {
    static isMobile() {
        if (typeof window === 'undefined')
            return false;
        return window.matchMedia(`(max-width: ${this.MOBILE_BREAKPOINT}px)`).matches || /Mobi|Android/i.test(navigator.userAgent);
    }
    static onResize(callback) {
        if (typeof window !== 'undefined') {
            this.resizeHandler = callback;
            window.addEventListener('resize', this.resizeHandler);
        }
    }
    static removeResize(callback) {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', callback);
        }
    }
    static destroy() {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.resizeHandler);
        }
    }
}
Mobile.MOBILE_BREAKPOINT = 768;
Mobile.resizeHandler = () => { };
