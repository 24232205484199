import * as THREE from 'three';
import Application from '../Application';
import fragmentShader from '../Shaders/coffee/fragment.glsl';
import vertexShader from '../Shaders/coffee/vertex.glsl';
export default class CoffeeSteam {
    constructor() {
        this.application = Application.getInstance();
        this.resources = this.application.resources;
        this.scene = this.application.scene;
        this.time = this.application.time;
        this.setModel();
    }
    setModel() {
        const material = new THREE.ShaderMaterial({
            transparent: true,
            depthWrite: false,
            vertexShader,
            fragmentShader,
            uniforms: {
                uTime: { value: 0 },
                uTimeFrequency: { value: 0.001 },
                uUvFrequency: { value: CoffeeSteam.UV_FREQUENCY },
                uColor: { value: new THREE.Color('#c9c9c9') },
            },
        });
        const geometry = new THREE.PlaneGeometry(CoffeeSteam.GEOMETRY_SIZE.x, CoffeeSteam.GEOMETRY_SIZE.y);
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.copy(CoffeeSteam.POSITION);
        this.model = { material, mesh };
        this.scene.add(mesh);
    }
    update() {
        var _a, _b, _c;
        if ((_c = (_b = (_a = this.model) === null || _a === void 0 ? void 0 : _a.material) === null || _b === void 0 ? void 0 : _b.uniforms) === null || _c === void 0 ? void 0 : _c.uTime) {
            this.model.material.uniforms.uTime.value = this.time.elapsed;
        }
    }
    destroy() {
        var _a;
        if ((_a = this.model) === null || _a === void 0 ? void 0 : _a.mesh) {
            this.scene.remove(this.model.mesh);
            if (this.model.material) {
                this.model.material.dispose();
                if (this.model.material.uniforms) {
                    Object.keys(this.model.material.uniforms).forEach((key) => {
                        this.model.material.uniforms[key].value = null;
                    });
                }
            }
            this.model.mesh.geometry.dispose();
            this.model.mesh = null;
            this.model.material = null;
        }
    }
}
CoffeeSteam.GEOMETRY_SIZE = new THREE.Vector2(280, 700);
CoffeeSteam.UV_FREQUENCY = new THREE.Vector2(3, 5);
CoffeeSteam.POSITION = new THREE.Vector3(-1800, -45, 1600);
