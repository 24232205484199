import EventEmitter from './EventEmitter';
export default class Sizes extends EventEmitter {
    constructor() {
        super();
        if (typeof window !== 'undefined') {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            this.pixelRatio = Math.min(window.devicePixelRatio, 2);
            this.resizeHandler = this.onResize.bind(this);
            window.addEventListener('resize', this.resizeHandler);
        }
    }
    onResize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.pixelRatio = Math.min(window.devicePixelRatio, 2);
        this.trigger('resize', [{ width: this.width, height: this.height, pixelRatio: this.pixelRatio }]);
    }
    destroy() {
        window.removeEventListener('resize', this.resizeHandler);
    }
}
