import * as THREE from 'three';
import Application from '../Application';
import { AmbienceAudio, ComputerAudio } from './AudioSources';
import UIEventBus from '../UI/EventBus';
const POS_DEBUG = false;
const DEFAULT_REF_DISTANCE = 10000;
export default class Audio {
    constructor() {
        this.application = Application.getInstance();
        this.listener = new THREE.AudioListener();
        this.application.camera.instance.add(this.listener);
        this.loadedAudio = this.application.resources.items.audio;
        this.scene = this.application.scene;
        this.audioPool = {};
        this.audioSources = {
            computer: new ComputerAudio(this),
            ambience: new AmbienceAudio(this),
        };
        UIEventBus.on('loadingScreenDone', () => {
            setTimeout(() => this.initializeAudioContext(), 100);
        });
        UIEventBus.on('muteToggle', (mute) => {
            this.listener.setMasterVolume(mute ? 0 : 1);
        });
    }
    initializeAudioContext() {
        var _a;
        if (!this.context || this.context.state === 'suspended') {
            this.context = new ((_a = window.AudioContext) !== null && _a !== void 0 ? _a : window.webkitAudioContext)();
            this.context.resume();
        }
    }
    playAudio(sourceName, options = {}) {
        this.initializeAudioContext();
        sourceName = this.getRandomVariant(sourceName);
        const buffer = this.loadedAudio[sourceName];
        const poolKey = sourceName + '_' + Object.keys(this.audioPool).length;
        let audio = new THREE.Audio(this.listener);
        if (options.position) {
            audio = new THREE.PositionalAudio(this.listener);
            if (audio instanceof THREE.PositionalAudio) {
                audio.setRefDistance(options.refDistance || DEFAULT_REF_DISTANCE);
            }
            const extraMaterialOptions = !POS_DEBUG ? { transparent: true, opacity: 0 } : {};
            const sphere = new THREE.SphereGeometry(100, 8, 8);
            const material = new THREE.MeshBasicMaterial(Object.assign({ color: 0xff0000 }, extraMaterialOptions));
            const mesh = new THREE.Mesh(sphere, material);
            mesh.position.copy(options.position);
            mesh.name = poolKey;
            this.scene.add(mesh);
        }
        audio.setBuffer(buffer);
        if (options.filter) {
            const ac = audio.context;
            const filter = ac.createBiquadFilter();
            filter.type = options.filter.type;
            filter.frequency.setValueAtTime(options.filter.frequency, ac.currentTime);
            audio.setFilter(filter);
        }
        audio.setLoop(options.loop || false);
        audio.setVolume(options.volume || 1);
        audio.play();
        const detuneAmount = (Math.random() * 200 - 100) * (options.randDetuneScale || 0);
        audio.setDetune(detuneAmount);
        if (options.pitch) {
            audio.setDetune(options.pitch * 100);
        }
        if (audio.source) {
            audio.source.onended = () => {
                delete this.audioPool[poolKey];
                if (options.position) {
                    const positionalObject = this.scene.getObjectByName(poolKey);
                    if (positionalObject instanceof THREE.Mesh) {
                        positionalObject.geometry.dispose();
                        positionalObject.material.dispose();
                        this.scene.remove(positionalObject);
                    }
                }
            };
            this.audioPool[poolKey] = audio;
        }
        return poolKey;
    }
    setAudioFilterFrequency(audio, frequency) {
        const a = this.audioPool[audio];
        if (a && a.getFilter()) {
            const filter = a.getFilter();
            filter.frequency.setValueAtTime(Math.max(0, Math.min(22050, frequency)), a.context.currentTime);
        }
    }
    setAudioVolume(audio, volume) {
        const a = this.audioPool[audio];
        if (a) {
            a.setVolume(volume);
        }
    }
    getRandomVariant(sourceName) {
        const variants = [];
        for (const key in this.loadedAudio) {
            if (key.includes(sourceName)) {
                variants.push(key);
            }
        }
        return variants.length > 0 ? variants[Math.floor(Math.random() * variants.length)] : sourceName;
    }
    update() {
        for (const key in this.audioSources) {
            const _key = key;
            this.audioSources[_key].update();
        }
    }
}
