import * as THREE from 'three';
import Application from '../Application';
export default class Pendant {
    constructor() {
        this.application = Application.getInstance();
        this.scene = this.application.scene;
        this.resources = this.application.resources;
        this.clock = new THREE.Clock();
        this.setModel();
        this.addLighting();
    }
    setModel() {
        this.pendant = this.resources.items.gltfModel.pendantModel.scene;
        const reflectiveMaterial = new THREE.MeshStandardMaterial({
            color: 0xeeeeee,
            metalness: 1,
            roughness: 0.4,
        });
        this.pendant.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = reflectiveMaterial;
            }
        });
        this.pendant.position.set(-3000, 0, 2400);
        this.pendant.scale.set(800, 800, 800);
        this.pendant.rotation.set(0, 0.66, 0);
        this.scene.add(this.pendant);
    }
    addLighting() {
        this.spotLight = new THREE.SpotLight(0xffe4b5, 5);
        this.spotLight.position.set(-3200, 2000, 2800);
        this.spotLight.angle = 0.2;
        this.spotLight.penumbra = 0.5;
        this.spotLight.decay = 1.5;
        this.spotLight.distance = 8000;
        this.spotLight.target = this.pendant;
        this.scene.add(this.spotLight);
    }
    update() {
        if (this.pendant) {
            const time = this.clock.getElapsedTime();
            const sinTime = Math.sin(time);
            const sinTime04 = Math.sin(time * 0.4);
            const sinTime05 = Math.sin(time * 0.5);
            this.pendant.rotation.x = sinTime04 * 0.1;
            this.pendant.rotation.y = 0.66 + sinTime * 0.2;
            this.pendant.rotation.z = sinTime05 * 0.1;
        }
    }
}
