import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import Application from '../Application';
import EventEmitter from './EventEmitter';
export default class Resources extends EventEmitter {
    constructor(sources) {
        super();
        this.sources = sources;
        this.items = { texture: {}, cubeTexture: {}, gltfModel: {}, audio: {} };
        this.toLoad = this.sources.length;
        this.loaded = 0;
        this.application = Application.getInstance();
        this.loading = this.application.loading;
        this.setLoaders();
        this.startLoading();
    }
    setLoaders() {
        this.loaders = {
            gltfLoader: new GLTFLoader(),
            textureLoader: new THREE.TextureLoader(),
            cubeTextureLoader: new THREE.CubeTextureLoader(),
            audioLoader: new THREE.AudioLoader(),
        };
    }
    startLoading() {
        for (const source of this.sources) {
            switch (source.type) {
                case 'gltfModel':
                    if (typeof source.path === 'string') {
                        this.loaders.gltfLoader.load(source.path, (file) => this.sourceLoaded(source, file), undefined, (error) => this.handleError(source, error));
                    }
                    else {
                        console.error(`Invalid path for GLTF Model: ${source.name}`);
                    }
                    break;
                case 'texture':
                    if (typeof source.path === 'string') {
                        this.loaders.textureLoader.load(source.path, (file) => {
                            file.encoding = THREE.sRGBEncoding;
                            this.sourceLoaded(source, file);
                        }, undefined, (error) => this.handleError(source, error));
                    }
                    else {
                        console.error(`Invalid path for Texture: ${source.name}`);
                    }
                    break;
                case 'cubeTexture':
                    if (Array.isArray(source.path)) {
                        this.loaders.cubeTextureLoader.load(source.path, (file) => {
                            file.encoding = THREE.sRGBEncoding;
                            this.sourceLoaded(source, file);
                        }, undefined, (error) => this.handleError(source, error));
                    }
                    else {
                        console.error(`Invalid path for Cube Texture: ${source.name}`);
                    }
                    break;
                case 'audio':
                    if (typeof source.path === 'string') {
                        this.loaders.audioLoader.load(source.path, (buffer) => this.sourceLoaded(source, buffer), undefined, (error) => this.handleError(source, error));
                    }
                    else {
                        console.error(`Invalid path for Audio: ${source.name}`);
                    }
                    break;
                default:
                    console.warn(`Unknown resource type: ${source.type}`);
            }
        }
    }
    sourceLoaded(source, file) {
        if (this.items[source.type]) {
            this.items[source.type][source.name] = file;
        }
        else {
            console.warn(`Invalid resource type: ${source.type}`);
        }
        this.loaded++;
        this.loading.trigger('loadedSource', [source.name, this.loaded, this.toLoad]);
        if (this.loaded === this.toLoad) {
            this.trigger('ready');
        }
    }
    handleError(source, error) {
        console.error(`Failed to load resource: ${source.name} (${source.type})`, error);
    }
    destroy() {
        this.sources = [];
        this.items = { texture: {}, cubeTexture: {}, gltfModel: {}, audio: {} };
        this.loaded = 0;
        this.toLoad = 0;
    }
}
