import UIEventBus from '../UI/EventBus';
import EventEmitter from './EventEmitter';
export default class Time extends EventEmitter {
    constructor() {
        super();
        this.tick = () => {
            if (this.isPaused)
                return;
            const currentTime = Date.now();
            this.delta = currentTime - this.current;
            this.current = currentTime;
            this.elapsed = this.current - this.start;
            this.trigger('tick', [this.elapsed, this.delta]);
            this.animationFrameId = window.requestAnimationFrame(this.tick);
        };
        this.start = Date.now();
        this.current = this.start;
        this.elapsed = 0;
        this.delta = 16;
        this.isPaused = false;
        this.tick();
        UIEventBus.on('loadingScreenDone', () => {
            this.start = Date.now();
        });
    }
    pause() {
        this.isPaused = true;
    }
    resume() {
        if (this.isPaused) {
            this.isPaused = false;
            this.tick();
        }
    }
    destroy() {
        if (this.animationFrameId !== undefined) {
            window.cancelAnimationFrame(this.animationFrameId);
        }
    }
}
