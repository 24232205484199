import Application from '../Application';
import EventEmitter from './EventEmitter';
import UIEventBus from '../UI/EventBus';
export default class Loading extends EventEmitter {
    constructor() {
        super();
        this.progress = 0;
        const appInstance = Application.getInstance();
        this.application = appInstance;
        this.resources = appInstance.resources;
        this.scene = appInstance.scene;
        this.on('loadedSource', (sourceName, loaded, toLoad) => {
            if (toLoad > 0) {
                this.progress = loaded / toLoad;
                UIEventBus.dispatch('loadedSource', {
                    sourceName: sourceName,
                    progress: this.progress,
                    toLoad: toLoad,
                    loaded: loaded,
                });
            }
            else {
                console.warn(`Loading.ts: Attempted to load with toLoad = ${toLoad}`);
            }
        });
    }
}
