var _a;
class UIEventBus {
    static on(event, callback) {
        if (!this.events.has(event)) {
            this.events.set(event, new Set());
        }
        this.events.get(event).add(callback);
        if (this.events.get(event).size === 1) {
            document.addEventListener(event, this.handleEvent);
        }
    }
    static dispatch(event, data) {
        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    }
    static remove(event, callback) {
        if (!this.events.has(event))
            return;
        this.events.get(event).delete(callback);
        if (this.events.get(event).size === 0) {
            document.removeEventListener(event, this.handleEvent);
            this.events.delete(event);
        }
    }
}
_a = UIEventBus;
UIEventBus.events = new Map();
UIEventBus.handleEvent = (e) => {
    const event = e;
    if (_a.events.has(event.type)) {
        _a.events.get(event.type).forEach((callback) => {
            callback(event.detail);
        });
    }
};
export default UIEventBus;
