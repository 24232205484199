export default class EventEmitter {
    constructor() {
        this.callbacks = new Map();
    }
    on(event, callback) {
        if (!event || typeof event !== 'string') {
            throw new Error('EventEmitter.on: Invalid event name');
        }
        if (typeof callback !== 'function') {
            throw new Error('EventEmitter.on: Callback must be a function');
        }
        const { namespace, value } = this.resolveName(event);
        if (!this.callbacks.has(namespace)) {
            this.callbacks.set(namespace, new Map());
        }
        if (!this.callbacks.get(namespace).has(value)) {
            this.callbacks.get(namespace).set(value, new Set());
        }
        this.callbacks.get(namespace).get(value).add(callback);
        return this;
    }
    off(event) {
        if (!event || typeof event !== 'string') {
            throw new Error('EventEmitter.off: Invalid event name');
        }
        const { namespace, value } = this.resolveName(event);
        if (value === '') {
            this.callbacks.delete(namespace);
        }
        else {
            const namespaceCallbacks = this.callbacks.get(namespace);
            if (namespaceCallbacks) {
                namespaceCallbacks.delete(value);
                if (namespaceCallbacks.size === 0) {
                    this.callbacks.delete(namespace);
                }
            }
        }
        return this;
    }
    trigger(event, args = []) {
        if (!event || typeof event !== 'string') {
            throw new Error('EventEmitter.trigger: Invalid event name');
        }
        const { namespace, value } = this.resolveName(event);
        let finalResult = undefined;
        if (this.callbacks.has(namespace)) {
            const namespaceCallbacks = this.callbacks.get(namespace);
            if (namespaceCallbacks && namespaceCallbacks.has(value)) {
                namespaceCallbacks.get(value).forEach(callback => {
                    const result = callback(...args);
                    if (finalResult === undefined) {
                        finalResult = result;
                    }
                });
            }
        }
        return finalResult;
    }
    resolveName(event) {
        const parts = event.split('.');
        return {
            original: event,
            value: parts[0],
            namespace: parts.length > 1 && parts[1] ? parts[1] : 'base'
        };
    }
}
