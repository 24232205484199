import * as THREE from 'three';
export default class BakedModel {
    constructor(model, texture, scale, transparent = false) {
        this.model = model;
        this.texture = texture;
        this.texture.flipY = false;
        this.texture.encoding = THREE.sRGBEncoding;
        this.material = new THREE.MeshBasicMaterial({
            map: this.texture,
            transparent: transparent,
        });
        if (scale) {
            this.model.scene.scale.set(scale, scale, scale);
        }
        this.model.scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = this.material;
                child.material.map = this.texture;
            }
        });
    }
    getModel() {
        return this.model.scene;
    }
}
