import * as THREE from 'three';
import Application from '../Application';
export default class Hitbox {
    constructor(eventType = 'click', visibleHitboxes = false) {
        this.handleClick = (event) => {
            event.preventDefault();
            this.raycaster.setFromCamera({ x: this.mouse.normalizedX, y: this.mouse.normalizedY }, this.camera.instance);
            const intersects = this.raycaster.intersectObjects(Object.values(this.hitboxes).map((hb) => hb.mesh));
            if (intersects.length > 0) {
                const clickedObject = intersects[0].object;
                const hb = this.hitboxes[clickedObject.name];
                if (hb) {
                    hb.action();
                }
            }
        };
        this.application = Application.getInstance();
        this.scene = this.application.scene;
        this.camera = this.application.camera;
        this.mouse = this.application.mouse;
        this.raycaster = new THREE.Raycaster();
        this.hitboxes = {};
        this.eventType = eventType;
        this.createRaycaster();
        this.createPendantHitbox(visibleHitboxes);
        this.createCdHitbox(visibleHitboxes);
    }
    createRaycaster() {
        window.addEventListener(this.eventType, this.handleClick);
    }
    createPendantHitbox(visible) {
        this.createHitbox('pendantHitbox', () => {
            console.log('Pendant hitbox clicked');
        }, new THREE.Vector3(-3000, 0, 2400), new THREE.Vector3(700, 700, 700), visible);
    }
    createCdHitbox(visible) {
        this.createHitbox('cdHitbox', () => {
            console.log('CD hitbox clicked');
        }, new THREE.Vector3(3200, -300, 3400), new THREE.Vector3(1200, 1000, 1200), visible);
    }
    createHitbox(name, action, position, size, visible) {
        const hitboxMaterial = new THREE.MeshBasicMaterial({
            color: 0xff0000,
            wireframe: visible,
            transparent: !visible,
            opacity: visible ? 0.3 : 0,
            depthWrite: visible,
        });
        const hitbox = new THREE.Mesh(new THREE.BoxBufferGeometry(size.x, size.y, size.z), hitboxMaterial);
        hitbox.name = name;
        hitbox.position.copy(position);
        this.scene.add(hitbox);
        this.hitboxes[name] = { mesh: hitbox, action };
    }
    destroy() {
        window.removeEventListener(this.eventType, this.handleClick);
        Object.values(this.hitboxes).forEach(({ mesh }) => {
            this.scene.remove(mesh);
            mesh.geometry.dispose();
            mesh.material.dispose();
        });
        this.hitboxes = {};
    }
}
