import { createUI, createVolumeUI } from './App';
export default class UI {
    constructor() {
        this.init();
    }
    init() {
        try {
            createUI();
            createVolumeUI();
        }
        catch (error) {
            console.error('Error initializing UI:', error);
        }
    }
    destroy() {
        console.log('Destroying UI...');
    }
}
