import * as THREE from 'three';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import Application from '../Application';
import EventEmitter from '../Utils/EventEmitter';
const SCREEN_SIZE = { w: 1280, h: 1024 };
const IFRAME_PADDING = 32;
const IFRAME_SIZE = {
    w: SCREEN_SIZE.w - IFRAME_PADDING,
    h: SCREEN_SIZE.h - IFRAME_PADDING,
};
export default class MonitorScreen extends EventEmitter {
    constructor() {
        super();
        this.handleMouseEvents = (event) => {
            var _a;
            this.inComputer = ((_a = event.target) === null || _a === void 0 ? void 0 : _a.id) === 'computer-screen';
            switch (event.type) {
                case 'mousemove':
                    this.application.mouse.trigger('mousemove', [event]);
                    if (this.inComputer && !this.prevInComputer) {
                        this.camera.trigger('enterMonitor');
                    }
                    else if (!this.inComputer && this.prevInComputer && !this.mouseClickInProgress) {
                        this.camera.trigger('leftMonitor');
                    }
                    this.shouldLeaveMonitor = !this.inComputer && this.mouseClickInProgress && this.prevInComputer;
                    break;
                case 'mousedown':
                    this.application.mouse.trigger('mousedown', [event]);
                    this.mouseClickInProgress = true;
                    break;
                case 'mouseup':
                    this.application.mouse.trigger('mouseup', [event]);
                    if (this.shouldLeaveMonitor) {
                        this.camera.trigger('leftMonitor');
                        this.shouldLeaveMonitor = false;
                    }
                    this.mouseClickInProgress = false;
                    break;
            }
            this.prevInComputer = this.inComputer;
        };
        this.application = Application.getInstance();
        this.scene = this.application.scene;
        this.cssScene = this.application.cssScene;
        this.sizes = this.application.sizes;
        this.resources = this.application.resources;
        this.screenSize = new THREE.Vector2(SCREEN_SIZE.w, SCREEN_SIZE.h);
        this.camera = this.application.camera;
        this.position = new THREE.Vector3(0, 950, 255);
        this.rotation = new THREE.Euler(-3 * THREE.MathUtils.DEG2RAD, 0, 0);
        this.videoTextures = {};
        this.mouseClickInProgress = false;
        this.shouldLeaveMonitor = false;
        this.initializeScreenEvents();
        this.createIframe();
        const maxOffset = this.createTextureLayers();
        this.createEnclosingPlanes(maxOffset);
        this.createPerspectiveDimmer(maxOffset);
    }
    initializeScreenEvents() {
        document.addEventListener('mousemove', this.handleMouseEvents);
        document.addEventListener('mousedown', this.handleMouseEvents);
        document.addEventListener('mouseup', this.handleMouseEvents);
    }
    createIframe() {
        const isMobile = this.sizes.width < 768;
        const container = document.createElement('div');
        container.style.width = this.screenSize.width + 'px';
        container.style.height = this.screenSize.height + 'px';
        container.style.opacity = '1';
        container.style.background = '#1d2e2f';
        const iframe = document.createElement('iframe');
        iframe.onload = () => {
            if (iframe.contentWindow) {
                window.addEventListener('message', (event) => {
                    var evt = new CustomEvent(event.data.type, {
                        bubbles: true,
                        cancelable: false,
                    });
                    evt.inComputer = true;
                    if (event.data.type === 'mousemove') {
                        var clRect = iframe.getBoundingClientRect();
                        const { top, left, width, height } = clRect;
                        const widthRatio = width / IFRAME_SIZE.w;
                        const heightRatio = height / IFRAME_SIZE.h;
                        evt.clientX = Math.round(event.data.clientX * widthRatio + left);
                        evt.clientY = Math.round(event.data.clientY * heightRatio + top);
                    }
                    else if (event.data.type === 'keydown' || event.data.type === 'keyup') {
                        if (typeof event.data.key === 'string') {
                            evt.key = event.data.key;
                        }
                    }
                    iframe.dispatchEvent(evt);
                });
            }
        };
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('dev')) {
            if (isMobile) {
                iframe.src = 'http://192.168.1.4:3001';
            }
            else {
                iframe.src = 'http://192.168.1.4:3000';
            }
        }
        else if (isMobile) {
            iframe.src = 'https://mobile.montypkos.com/';
        }
        else {
            iframe.src = 'https://montypkos.com/';
        }
        iframe.style.width = this.screenSize.width + 'px';
        iframe.style.height = this.screenSize.height + 'px';
        iframe.style.padding = IFRAME_PADDING + 'px';
        iframe.style.boxSizing = 'border-box';
        iframe.style.opacity = '1';
        iframe.className = 'jitter';
        iframe.id = 'computer-screen';
        iframe.frameBorder = '0';
        iframe.title = 'HeffernanOS';
        container.appendChild(iframe);
        this.createCssPlane(container);
    }
    createCssPlane(element) {
        const object = new CSS3DObject(element);
        object.position.copy(this.position);
        object.rotation.copy(this.rotation);
        this.cssScene.add(object);
        const material = new THREE.MeshLambertMaterial();
        material.side = THREE.DoubleSide;
        material.opacity = 0;
        material.transparent = true;
        material.blending = THREE.NoBlending;
        const geometry = new THREE.PlaneGeometry(this.screenSize.width, this.screenSize.height);
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.copy(object.position);
        mesh.rotation.copy(object.rotation);
        mesh.scale.copy(object.scale);
        this.scene.add(mesh);
    }
    createTextureLayers() {
        const textures = this.resources.items.texture;
        const scaleFactor = 4;
        this.getVideoTextures('video-1');
        this.getVideoTextures('video-2');
        const layers = [
            { texture: textures.monitorSmudgeTexture, blending: THREE.AdditiveBlending, opacity: 0.12, offset: 24 },
            { texture: textures.monitorShadowTexture, blending: THREE.NormalBlending, opacity: 1, offset: 5 },
            { texture: this.videoTextures['video-1'], blending: THREE.AdditiveBlending, opacity: 0.3, offset: 10 },
            { texture: this.videoTextures['video-2'], blending: THREE.AdditiveBlending, opacity: 0.1, offset: 15 },
        ];
        const textureMeshes = layers.map(layer => {
            const offset = layer.offset * scaleFactor;
            return {
                mesh: this.createTextureMesh(layer.texture, layer.blending, layer.opacity, offset),
                offset
            };
        });
        const maxOffset = Math.max(...textureMeshes.map(t => t.offset));
        textureMeshes.forEach(({ mesh }) => this.scene.add(mesh));
        return maxOffset;
    }
    createTextureMesh(texture, blending, opacity, offset) {
        const material = new THREE.MeshBasicMaterial({
            map: texture,
            blending,
            side: THREE.DoubleSide,
            opacity,
            transparent: true,
        });
        const geometry = new THREE.PlaneGeometry(this.screenSize.width, this.screenSize.height);
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.copy(this.offsetPosition(this.position, new THREE.Vector3(0, 0, offset)));
        mesh.rotation.copy(this.rotation);
        return mesh;
    }
    getVideoTextures(videoId) {
        const video = document.getElementById(videoId);
        if (!video)
            return;
        video.crossOrigin = "anonymous";
        video.muted = true;
        video.playsInline = true;
        video.load();
        video.play();
        const texture = new THREE.VideoTexture(video);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.format = THREE.RGBAFormat;
        texture.encoding = THREE.sRGBEncoding;
        texture.needsUpdate = true;
        this.videoTextures[videoId] = texture;
        this.application.updateLoop(() => {
            if (this.videoTextures[videoId] && video.readyState >= 2) {
                this.videoTextures[videoId].needsUpdate = true;
            }
        });
        console.log(`Video texture ${videoId} created successfully.`);
    }
    addTextureLayer(texture, blendingMode, opacity, offset) {
        const material = new THREE.MeshBasicMaterial({
            map: texture,
            blending: blendingMode,
            side: THREE.DoubleSide,
            opacity: 0.5,
            transparent: true,
            premultipliedAlpha: false,
        });
        const geometry = new THREE.PlaneGeometry(this.screenSize.width, this.screenSize.height);
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.copy(this.offsetPosition(this.position, new THREE.Vector3(0, 0, offset)));
        mesh.rotation.copy(this.rotation);
        this.scene.add(mesh);
    }
    createEnclosingPlanes(maxOffset) {
        const planes = {
            left: {
                size: new THREE.Vector2(maxOffset, this.screenSize.height),
                position: this.offsetPosition(this.position, new THREE.Vector3(-this.screenSize.width / 2, 0, maxOffset / 2)),
                rotation: new THREE.Euler(0, 90 * THREE.MathUtils.DEG2RAD, 0),
            },
            right: {
                size: new THREE.Vector2(maxOffset, this.screenSize.height),
                position: this.offsetPosition(this.position, new THREE.Vector3(this.screenSize.width / 2, 0, maxOffset / 2)),
                rotation: new THREE.Euler(0, 90 * THREE.MathUtils.DEG2RAD, 0),
            },
            top: {
                size: new THREE.Vector2(this.screenSize.width, maxOffset),
                position: this.offsetPosition(this.position, new THREE.Vector3(0, this.screenSize.height / 2, maxOffset / 2)),
                rotation: new THREE.Euler(90 * THREE.MathUtils.DEG2RAD, 0, 0),
            },
            bottom: {
                size: new THREE.Vector2(this.screenSize.width, maxOffset),
                position: this.offsetPosition(this.position, new THREE.Vector3(0, -this.screenSize.height / 2, maxOffset / 2)),
                rotation: new THREE.Euler(90 * THREE.MathUtils.DEG2RAD, 0, 0),
            },
        };
        for (const [_, plane] of Object.entries(planes)) {
            this.createEnclosingPlane(plane);
        }
    }
    createEnclosingPlane(plane) {
        const material = new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide,
            color: 0x48493f,
        });
        const geometry = new THREE.PlaneGeometry(plane.size.x, plane.size.y);
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.copy(plane.position);
        mesh.rotation.copy(plane.rotation);
        this.scene.add(mesh);
    }
    createPerspectiveDimmer(maxOffset) {
        const material = new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide,
            color: 0x000000,
            transparent: true,
            blending: THREE.AdditiveBlending,
        });
        const plane = new THREE.PlaneGeometry(this.screenSize.width, this.screenSize.height);
        const mesh = new THREE.Mesh(plane, material);
        mesh.position.copy(this.offsetPosition(this.position, new THREE.Vector3(0, 0, maxOffset - 5)));
        mesh.rotation.copy(this.rotation);
        this.dimmingPlane = mesh;
        this.scene.add(mesh);
    }
    offsetPosition(position, offset) {
        const newPosition = new THREE.Vector3();
        newPosition.copy(position);
        newPosition.add(offset);
        return newPosition;
    }
    update() {
        if (this.dimmingPlane) {
            const planeNormal = new THREE.Vector3(0, 0, 1);
            const viewVector = new THREE.Vector3();
            viewVector.copy(this.camera.instance.position);
            viewVector.sub(this.position);
            viewVector.normalize();
            const dot = viewVector.dot(planeNormal);
            const dimPos = this.dimmingPlane.position;
            const camPos = this.camera.instance.position;
            const distance = Math.sqrt(Math.pow((camPos.x - dimPos.x), 2) +
                Math.pow((camPos.y - dimPos.y), 2) +
                Math.pow((camPos.z - dimPos.z), 2));
            const opacity = 1 / (distance / 10000);
            const DIM_FACTOR = 0.7;
            this.dimmingPlane.material.opacity =
                (1 - opacity) * DIM_FACTOR + (1 - dot) * DIM_FACTOR;
        }
    }
}
