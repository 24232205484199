import * as THREE from 'three';
import Application from '../Application';
import BakedModel from '../Utils/BakedModel';
export default class Environment {
    constructor() {
        this.application = Application.getInstance();
        this.scene = this.application.scene;
        this.resources = this.application.resources;
        this.initModel();
    }
    initModel() {
        var _a, _b;
        const model = (_a = this.resources.items.gltfModel) === null || _a === void 0 ? void 0 : _a.environmentModel;
        const texture = (_b = this.resources.items.texture) === null || _b === void 0 ? void 0 : _b.environmentTexture;
        if (!model || !texture) {
            console.error('Environment.ts: Missing model or texture resources');
            return;
        }
        this.bakedModel = new BakedModel(model, texture, Environment.MODEL_SCALE, true);
        this.scene.add(this.bakedModel.getModel());
    }
    setRenderOrder(order = 1) {
        if (!this.bakedModel)
            return;
        this.bakedModel.getModel().traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.renderOrder = order;
            }
        });
    }
    update() { }
    destroy() {
        if (this.bakedModel) {
            this.scene.remove(this.bakedModel.getModel());
        }
    }
}
Environment.MODEL_SCALE = 900;
