import * as THREE from 'three';
import Debug from './Utils/Debug';
import Sizes from './Utils/Sizes';
import Time from './Utils/Time';
import Camera from './Camera/Camera';
import Renderer from './Renderer';
import Mouse from './Utils/Mouse';
import Hitbox from './World/Hitbox';
import World from './World/World';
import Resources from './Utils/Resources';
import sources from './sources';
import Stats from 'stats.js';
import Loading from './Utils/Loading';
import UI from './UI';
export default class Application {
    constructor() {
        this.isRunning = true;
        if (Application.instance) {
            return Application.instance;
        }
        Application.instance = this;
        this.debug = new Debug();
        this.sizes = new Sizes();
        this.mouse = new Mouse();
        this.loading = new Loading();
        this.time = new Time();
        this.scene = new THREE.Scene();
        this.cssScene = new THREE.Scene();
        this.overlayScene = new THREE.Scene();
        this.resources = new Resources(sources);
        this.camera = new Camera();
        this.renderer = new Renderer();
        this.camera.createControls();
        this.world = new World();
        this.hitboxManager = new Hitbox();
        this.ui = new UI();
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('debug'))
            this.enableDebug();
        this.sizes.on('resize', this.resize.bind(this));
        this.time.on('tick', this.update.bind(this));
    }
    static getInstance() {
        if (!Application.instance) {
            Application.instance = new Application();
        }
        return Application.instance;
    }
    enableDebug() {
        if (!this.stats) {
            this.stats = new Stats();
            this.stats.showPanel(0);
            document.body.appendChild(this.stats.dom);
        }
    }
    disableDebug() {
        if (this.stats) {
            document.body.removeChild(this.stats.dom);
            this.stats = undefined;
        }
    }
    resize() {
        this.camera.resize();
        this.renderer.resize();
    }
    update() {
        if (this.stats)
            this.stats.begin();
        this.camera.update();
        this.world.update();
        this.renderer.update();
        if (this.stats)
            this.stats.end();
    }
    updateLoop(callback) {
        this.isRunning = true;
        const loop = () => {
            if (!this.isRunning)
                return;
            callback();
            requestAnimationFrame(loop);
        };
        loop();
    }
    destroy() {
        this.sizes.on('resize', () => this.resize());
        this.time.on('tick', () => this.update());
        this.isRunning = false;
        this.scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.geometry.dispose();
                if (Array.isArray(child.material)) {
                    child.material.forEach((mat) => mat.dispose());
                }
                else {
                    child.material.dispose();
                }
            }
            this.scene.remove(child);
        });
        this.renderer.instance.dispose();
        if (this.debug.active)
            this.debug.ui.destroy();
        Application.instance = undefined;
    }
}
