import Application from '../Application';
import ComputerSetup from './Computer';
import MonitorScreen from './MonitorScreen';
import Environment from './Environment';
import Decor from './Decor';
import Smoke from './Smoke';
import Hitbox from './Hitbox';
import AudioManager from '../Audio/AudioManager';
import WindowView from './WindowView';
import Pendant from './Pendant';
export default class World {
    constructor() {
        this.application = Application.getInstance();
        this.scene = this.application.scene;
        this.resources = this.application.resources;
        this.resources.on('ready', this.initializeObjects.bind(this));
    }
    initializeObjects() {
        const objects = {
            environment: Environment,
            decor: Decor,
            computerSetup: ComputerSetup,
            monitorScreen: MonitorScreen,
            smoke: Smoke,
            audioManager: AudioManager,
            windowView: WindowView,
            pendant: Pendant,
            hitbox: Hitbox,
        };
        Object.entries(objects).forEach(([key, ObjClass]) => {
            this[key] = new ObjClass();
        });
    }
    update() {
        const updatableObjects = [this.monitorScreen, this.environment, this.smoke, this.audioManager, this.pendant];
        updatableObjects.forEach((obj) => (obj === null || obj === void 0 ? void 0 : obj.update) && obj.update());
    }
}
